function Nodata() {
    return ( 
        <div className="story-step-form mt-3 p-5 ">
        <div className="body-popup-title loadingtext text-center">
            <h3>Nothing to see !!</h3>
        </div>
    </div>
     );
}

export default Nodata;