function Heading() {
    return (

        <>
            <div className="story">
                <h2> Tale Treats</h2>
                <div className="top-line"></div>
            </div>


        </>
    );
}

export default Heading;