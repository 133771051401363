function Loading() {
    return (<>
        <div className="story-step-form mt-5 p-5">
            <div className="body-popup-title loadingtext text-center">
                    <div className="loaderpage">
                    <span className="loader-1"> </span>
                    </div>
            </div>
        </div>
    </>);
}

export default Loading;