import React from 'react'
import Heading from './Heading'

export default function Term() {
    return (
        <div>
            <div className="content-wrapper">
                <div className="content"></div>
                <Heading />
                <div class="privacy-sec">
                    <div class="privacy-head">
                        <div class="container">
                            <h1>Terms and Conditions</h1>
                        </div>
                    </div>
                    <div class="container">
                        <p>Last Updated: 01/04/2024
                        </p>
                        <p>Welcome to Tale Treats, a mobile application ("App") designed to provide personalized stories generated by artificial intelligence ("AI") for kids. These Terms and Conditions ("Terms") outline the terms of use for the Tale Treats App. By accessing or using the Tale Treats App, you agree to comply with these Terms. If you do not agree with these Terms, please refrain from using our App.</p>
                        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use
                            of information in accordance with this Privacy Policy. </p>
                        <h3>1. Acceptance of Terms</h3>
                        <p>By accessing or using the Tale Treats App, you affirm that you are at least 18 years old or possess the legal capacity to enter into a binding agreement. If you are using the App on behalf of a minor, you are responsible for ensuring their compliance with these Terms.</p>
                        <h4>2. Free Trial and Subscription Models</h4>
                        <p>Tale Treats offers a free trial period of three (3) days for users to experience the App. After the free trial period, users must purchase a subscription to continue using the App's services. By subscribing to any of our plans, you agree to the associated fees and payment terms indicated within the App.
                        </p>
                        <h3>
                            3. Content Generation by AI
                        </h3>
                        <ul>
                            <li><strong>a.</strong>All stories and images provided by Tale Treats are generated solely by artificial intelligence ("AI"). The content is automatically created based on user preferences, input, and AI algorithms. Tale Treats does not manually curate or create content.
                            </li>
                            <li><strong>b.</strong> Users acknowledge that AI-generated content may vary in quality and appropriateness. Although we aim to provide age-appropriate stories and images for children, we cannot guarantee that each story or image will be suitable for every child. It remains the responsibility of the user (or the user's parent/guardian) to ensure that the content aligns with the child's age and maturity level.
                            </li>
                        </ul>
                        <h3>4. Inappropriate Content</h3>
                        <p>
                            Tale Treats employs AI filters and content moderation mechanisms to detect and prevent inappropriate content. Nevertheless, due to the inherent nature of AI-generated content, there may be occasions when content is inaccurately flagged or inappropriate content passes through the filters.
                        </p>
                        <ul>
                            <li><strong>a.</strong> Users are encouraged to report any inappropriate content encountered on the App. Tale Treats will promptly investigate and take appropriate action in response to such reports.
                            </li>
                            <li><strong>b.</strong> Users understand and agree that while Tale Treats takes reasonable measures to prevent inappropriate content, we cannot guarantee that all content will be devoid of inaccuracies, errors, or potentially offensive material.
                            </li>
                            <li><strong>c.</strong> Users expressly acknowledge and agree that Tale Treats will not be held liable or responsible for any content generated by AI that may be considered inappropriate or offensive.
                            </li>
                        </ul>
                        <h3>
                            5. Daily Personalized Stories
                        </h3>
                        <p>
                            Tale Treats provides a new personalized story each morning for users based on user preferences and input.
                        </p>
                        <h3>
                            6. Intellectual Property
                        </h3>
                        <ul>
                            <li><strong>a.</strong>All content within the Tale Treats App, including text, graphics, images, and software, is the intellectual property of Tale Treats or its licensors. It is protected by copyright laws.
                            </li>
                            <li><strong>b.</strong>Users may not copy, reproduce, distribute, modify, or create derivative works from any content found within the Tale Treats App without the explicit written consent of Tale Treats.
                            </li>
                        </ul>

                        <h3>

                            7. Privacy

                        </h3>
                        <p>

                            Tale Treats is committed to safeguarding your privacy. Please review our Privacy Policy for comprehensive information regarding the collection, use, and protection of your personal data.

                        </p>
                        <h3>
                            8. Termination
                        </h3>
                        <p>Tale Treats reserves the right to terminate or suspend access to the App at any time, without notice, for any reason, including a violation of these Terms.
                        </p>

                        <h3>

                            9. Disclaimer of Warranty

                        </h3>
                        <p>
                            Tale Treats provides the App on an "as is" basis, and we do not offer any warranties, either express or implied, regarding the functionality, accuracy, or availability of the App. We disclaim all warranties, including implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                        </p>
                        <h3>
                            10. Limitation of Liability
                        </h3>
                        <p>
                            Tale Treats shall not be held liable for any indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, revenue, data, use, goodwill, or other intangible losses, arising directly or indirectly from the use or inability to use the Tale Treats App.

                        </p>
                        <h3>

                            11. Changes to Terms

                        </h3>
                        <p>
                            Tale Treats reserves the right to amend or update these Terms at any time. Users will be notified of significant changes within the App. By continuing to use the App after such modifications, you acknowledge your acceptance of the revised Terms.
                        </p>
                        <h3>
                            12. Contact Us
                        </h3>
                        <p>
                            For questions or concerns related to these Terms or the Tale Treats App, please contact us at taletreats.info@gmail.com
                        </p>
                        <p>
                            By utilizing the Tale Treats App, you acknowledge that you have read, comprehended, and consented to these Terms and our Privacy Policy. Please carefully review these documents and ensure compliance while using our App.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
