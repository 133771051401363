import React from 'react'
import Heading from './Heading'

export default function Privacy() {
    return (
        <div>
            <div className="content-wrapper">
                <div className="content"></div>
                <Heading />
                <div class="privacy-sec">
                    <div class="privacy-head">
                        <div class="container">
                            <h1> Privacy Policy</h1>
                        </div>
                    </div>
                    <div class="container">
                        <p>Last Updated: 01/04/2024</p>
                        <p>Welcome to Tale Treats, a mobile application ("App") focused on creating personalized stories via artificial intelligence ("AI") for kids. At Tale Treats, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our App. By accessing or using the Tale Treats App, you consent to the practices described in this Privacy Policy.</p>
                        <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use
                            of information in accordance with this Privacy Policy. </p>
                        <h3>1. Information We Collect</h3>
                        <h4>Personal Information</h4>
                        <p>To provide you with personalized stories and images generated by AI, we collect the following personal information</p>

                        <ul>
                            <li><strong>Name:</strong> We collect your name to personalize your experience within the App.</li>
                            <li><strong>Birthdate:</strong>  We collect your birthdate to verify your age and provide age-appropriate content.
                            </li>
                            <li><strong>Age:</strong> We collect your age to tailor the content generated by AI to your age group.
                            </li>
                            <li><strong>Story Preferences:</strong>We collect information about your story preferences to generate stories that align with your interests.
                            </li>
                        </ul>
                        <h4> b. Subscription Information</h4>
                        <p>When you subscribe to our premium services, including subscription charges, we do not collect your payment information directly. Payments are processed through your Apple account, and we do not have access to your payment details</p>
                        <h3>
                            2. How We Use Your Information
                        </h3>
                        <ul>
                            <li><strong>Personalization</strong>We use the information you provide, such as your age and story preferences, to generate personalized stories and images for your daily enjoyment within the App.

                            </li>

                            <li><strong>Communication</strong> We may use your name to address you in-app and send you notifications about your personalized stories.
                            </li>
                        </ul>
                        <h3>3. Data Security</h3>
                        <p>
                            We prioritize the security of your data and employ industry-standard measures to protect it from unauthorized access, disclosure, alteration, and destruction. Our security measures include encryption, access controls, and regular security audits.

                        </p>
                        <h3>4. Children's Privacy</h3>
                        <p>
                            The Tale Treats App is designed for children and is intended for use by children with parental consent. We do not knowingly collect personal information from children under the age of 13 without parental consent. If we become aware of any such data, we will take immediate steps to delete it.
                        </p>
                        <h3>
                            5. Disclosure of Information
                        </h3>
                        <p>
                            We do not share your personal information with third parties, and it is exclusively used within the App. We do not engage in selling or sharing user data for marketing purposes.
                        </p>
                        <h3>
                            6. Changes to this Privacy Policy
                        </h3>
                        <p>
                            Tale Treats reserves the right to modify this Privacy Policy at any time. We will notify you of significant changes by updating the "Last Updated" date at the beginning of this policy. We encourage you to review this policy periodically for any updates.
                        </p>
                        <h3>7. Contact Us</h3>
                        <p>If you have questions, concerns, or requests related to this Privacy Policy or the Tale Treats App, please contact us at: taletreats.info@gmail.com
                            By using the Tale Treats App, you acknowledge that you have read, understood, and consented to this Privacy Policy. Please ensure that you review and comply with this policy while using our App. Your trust and privacy are of utmost importance to us.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    )
}

